import React from 'react';
import { IconContext } from 'react-icons/lib';
import * as BsIcons from 'react-icons/bs';
import * as FiIcons from 'react-icons/fi';
import * as IoIcons from 'react-icons/io';

import classes from './VisionMissions.module.css';

const AboutUs = (props) => {
    return (
        <React.Fragment>
        {/* <div className={classes.BackDrop}></div> */}
        <div className={classes.Photo}></div>
            {/* <img 
                src={Backdrop} 
                alt="Banner"
                className="img-fluid"
            /> */}
            
            <div className={classes.Body}>
                <div className={[classes.Container,"container"].join(" ")}>
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-5"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h1 className={classes.Title}>願景及使命</h1>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-12 col-lg-7"].join(" ")}>
                        <IconContext.Provider value={{size: "50px"}}>
                        <div className={[classes.Col,"col-12 col-lg-12 .h-100"].join(" ")}>
                            <div className={[classes.Card, classes.Card1,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h4 className={classes.CardTitle1}>
                                        <BsIcons.BsEyeFill className={classes.Icon1}/>
                                        願景
                                    </h4>
                                    <p className={classes.Content}>
                                        翰林薈矢志成為一所卓越的升學教育機構，為眾家長及學生提供專業而優質的升學協助。
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-12 col-lg-12 .h-100"].join(" ")}>
                            <div className={[classes.Card, classes.Card1,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h4 className={classes.CardTitle1}>
                                        <IoIcons.IoIosRocket className={classes.Icon1}/>
                                        使命
                                    </h4>
                                    <p className={classes.Content}>
                                        <ul className={classes.list2}>
                                            <li>提供專業及優質的課程，為學生作充足的升學準備</li>
                                            <li>締造完善的學習空間，讓學生投入學習，提升學習興趣及效果</li>
                                            <li>持續搜集最新的升學資訊，予家長及學生參考相關資料</li>
                                            <li>積極聆聽家長及學生的需要，提供合適建議</li>
                                            <li>與不同界別的機構攜手合作，以協助學生裝備自己</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-12 col-lg-12 .h-100"].join(" ")}>
                            <div className={[classes.Card, classes.Card1,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h4 className={classes.CardTitle1}>
                                        <FiIcons.FiTarget className={classes.Icon1}/>
                                        價值觀
                                    </h4>
                                    <p className={classes.Content}>
                                        <ul className={classes.list2}>
                                            <li><span>追求卓越</span> – 持續以最優質的教育服務為目標</li>
                                            <li><span>秉持教育熱誠</span> – 盡心盡力教好每一位學生</li>
                                            <li><span>關愛與包容</span> – 以關愛與包容的心態引導學童</li>
                                            <li><span>團結</span> – 建立具團體精神的教學團隊，為家長及學生服務</li>
                                            <li><span>致力創新</span> – 積極尋求教學上不同的可能性，以達到最佳的教學效果</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        </IconContext.Provider>
                        </div>
                    </div>
                </div>
            </div>
            
            
        </React.Fragment>
    );
};

export default AboutUs;