import React from 'react';
import { IconContext } from 'react-icons/lib';
import * as FaIcons from 'react-icons/fa';
import * as ImIcons from 'react-icons/im';

import classes from './ContactUs.module.css';

import Building from '@assets/images/contact-us-building.jpg';

const ContactUs = (props) => {
    return (
        <React.Fragment>
            {/* <img src={Backdrop} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/> */}
            <div className={classes.Body}>
                <div className={[classes.Container,"container"].join(" ")}>
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h1 className={classes.Title}>聯絡我們</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Row, "row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-md-6 col-xl-3"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody1, "card-body"].join(" ")}>
                                    <div className={['container'].join(' ')}>
                                        <div className={['row'].join(' ')}>
                                            <div className={['col-3'].join(' ')}>
                                                <IconContext.Provider 
                                                    value={{ 
                                                        color: "var(--dark-theme-color)", 
                                                        size: '30px',
                                                        className: classes.TelIcon
                                                }}>
                                                    <p className={classes.Icon}><FaIcons.FaPhoneAlt/></p> 
                                                </IconContext.Provider>
                                            </div>
                                            <div className={['col-9'].join(' ')}>
                                                <span className={classes.AddressTitle1}>電話 :</span><p className={[classes.TelText]}>6329 1282</p>
                                            </div>
                                        </div>
                                    </div>                                   
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-12 col-md-6 col-xl-3"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody1, "card-body"].join(" ")}>
                                    <div className={['container'].join(' ')}>
                                        <div className={['row'].join(' ')}>
                                                <div className={['col-3'].join(' ')}>
                                                    <IconContext.Provider 
                                                        value={{ 
                                                            color: "var(--dark-theme-color)", 
                                                            size: '39px',
                                                            // className: classes.WhatsappIcon
                                                        }}>
                                                        <p className={classes.Icon}><FaIcons.FaWhatsapp/></p>
                                                    </IconContext.Provider>
                                                </div>
                                                <div className={['col-9'].join(' ')}>
                                                <span className={classes.AddressTitle2}>Whatsapp :</span><p className={[classes.TelText2]}>6329 1282</p>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody1, "card-body"].join(" ")}>
                                    <div className={['container'].join(' ')}>
                                        <div className={['row'].join(' ')}>
                                                <div className={['col-3 col-md-2 col-xl-2'].join(' ')}>
                                                <IconContext.Provider 
                                                    value={{ 
                                                        color: "var(--dark-theme-color)", 
                                                        size: '40px'
                                                        // className: classes.AddressIcon
                                                    }}>
                                                    <p className={classes.Icon}><ImIcons.ImLocation/></p>
                                                </IconContext.Provider>
                                                </div>
                                                <div className={['col-9 col-md-10 col-xl-10'].join(' ')}>
                                                    <span className={classes.AddressTitle}>地址 :</span>
                                                    <p className={[classes.AddressText]}>九龍佐敦柯士甸道102號</p>
                                                    <p className={[classes.AddressSpan]}>(近佐敦港鐵站D出口)</p>
                                                </div>
                                        </div>
                                    </div>
                                    <p>
                                        
                                    </p> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-7"].join(" ")}>
                            <div className={[classes.Card, classes.Card3,"card h-100"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                <iframe 
                                    className="w-100 h-100"
                                    width="590" 
                                    height="300" 
                                    style={{border: "0"}} 
                                    allowfullscreen="" 
                                    loading="lazy" 
                                    title="map"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1249.751887250067!2d114.17193886050856!3d22.303266209222663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ebf492bfc5%3A0x9cda06757352b875!2s102%20Austin%20Rd%2C%20Tsim%20Sha%20Tsui%2C%20Hong%20Kong!5e0!3m2!1sen!2sus!4v1633410635254!5m2!1sen!2sus">
                                </iframe>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-12 col-lg-5"].join(" ")}>
                            <div className={[classes.Card, classes.Card3,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <img src={Building} alt="Building" className={[classes.Image,"img-fluid"].join(" ")}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};


//<img src={Backdrop} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/>
export default ContactUs;