import React, { useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import * as IoIcons from 'react-icons/io5';

import classes from './NavBar.module.css';
import '@components/Global.css';

import Logo from '@assets/logo/logo-white.png';
import MenuItems from './Topbar/Topbar';
import Sidebar from './Sidebar/Sidebar';

const NavBar = (props) => {

    const [navSize, setNavSize] = useState("120px");
    const [logoSize, setLogoSize] = useState("60px");
    const [bottom, setBottom] = useState("5px solid var(--dark-theme-color)");
    const [shadow, setShadow] = useState("none");
    const [padding, setPadding] = useState("50px 1rem");

    const listenScrollEvent = () => {
        if ( window.scrollY > 6){
            setNavSize("80px");
            setLogoSize("40px");
            setBottom("none");
            setShadow("0px 2px 20px 0px rgba(0,0,0, 0.15)");
            setPadding("30px 1rem");
        } else {
            setNavSize("120px");
            setLogoSize("60px");
            setBottom("5px solid var(--dark-theme-color)");
            setShadow("none");
            setPadding("50px 1rem");
        }

    }
    
    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
        return () => {
            window.removeEventListener("scroll", listenScrollEvent);
        };
    }, []);

    return (
        <React.Fragment>
            <div 
                className={["", classes.NavBar].join("")}
                style={{
                    height: navSize,
                    borderBottom: bottom,
                    boxShadow: shadow,
                    transition: "all 0.7s",
                }}
            >   
                    <Link to="/">
                        <img
                        alt="Premier Scholars Logo"
                        style={{
                            height: logoSize,
                            transition: "all 0.7s",
                        }}
                        src={Logo}
                        />
                    </Link>
                    <div>
                        <div className={classes.NavItemsBox}>
                            <MenuItems padding={padding} navSize={navSize} />
                        </div>

                        <a  data-bs-toggle="offcanvas" 
                            href="#offcanvasExample" 
                            role="button" 
                            aria-controls="offcanvasExample">
                            <IconContext.Provider value={{size: "45px"}}>
                                <IoIcons.IoMenuSharp className={classes.MenuIcon}/>
                            </IconContext.Provider>
                        </a>
                        <Sidebar/>
                    </div>
            </div>
        </React.Fragment>
    );
};

export default NavBar;