import React from 'react';

import classes from './Regular.module.css';

import Chart from '@assets/background/chart-regular.jpg';

const Regular = (props) => {
    return (
        <React.Fragment>
        {/* <div className={classes.BackDrop}></div> */}
        <div className={classes.Photo}></div>
        {/* <img src={Backdrop} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/> */}
        <div className={classes.Body}>
            <div className={[classes.Container,"container"].join(" ")}>
                <div className={[classes.Row,"row"].join(" ")}>
                    <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={[classes.CardBody,"card-body"].join(" ")}>
                                <h1 className={classes.Title}>升中面試常規班<span className={classes.Grade}>（小五 – 小六）</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={[classes.Row,"row"].join(" ")}>
                    <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={[classes.CardBody,"card-body"].join(" ")}>
                                <p className={classes.Text}>升中面試技巧課程以最常見的面試形式為課程核心課題，教授同學應對不同的熱門形式及問題，協助他們提升面試表現，作全方位的準備。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={Chart} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/>
                <div className={[classes.Row, "row"].join(" ")}>
                    <div className={[classes.Col,"col-12 col-xl-6"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={["card-body"].join(" ")}>
                                <p className={classes.TextTitle}>課程簡介︰</p>
                                <ul className={[classes.List, classes.Text].join(" ")}>
                                    <li>以最常見的面試形式為課程核心</li>
                                    <li>大量熱門問題給予學生練習</li>
                                    <li>貼近最新面試趨勢</li>
                                    <li>進行模擬面試並附上表現報告</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Col,"col-12 col-xl-6"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={["card-body"].join(" ")}>
                                <p className={classes.TextTitle}>課程大綱：</p>
                                <ul className={[classes.List3, classes.Text].join(" ")}>
                                    <li>自我介紹</li>	
                                    <li>英語對答</li>
                                    <li>朗讀文章</li>	 	
                                    <li>看圖描述</li>
                                    <li>個人短講</li>	
                                    <li>時事討論</li>
                                    <li>小組討論</li>	
                                    <li>模擬面試</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Col,"col-12 col-xl-6"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={["card-body"].join(" ")}>
                                <p className={classes.TextTitle}>其他資料：</p>
                                <ul className={[classes.List2, classes.Text].join(" ")}>
                                    <li><span>對象 ︰</span> 小五、小六學生</li>
                                    <li><span>教學語言 ：</span> 英語 & 廣東話</li>
                                    <li><span>師生比例 ：</span> 最多6人一班</li>
                                    <li><span>學費	 ：</span>	$2800</li>	
                                    <li><span style={{color: "var(--pink-bg-color)"}}>課程共8堂，每堂1小時30分鐘 (包每名學生一本指定教材)</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Col,"col-12 col-xl-6"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={["card-body"].join(" ")}>
                                <p className={classes.TextTitle} 
                                    style={{
                                        background: "var(--dark-theme-color)", 
                                        color: "var(--gold-theme-color)"
                                    }}>升中面試常規班<span> – 自組班*</span></p>
                                <ul className={[classes.List2, classes.Text].join(" ")}>
                                    <li>學生可以<span style={{color: "var(--pink-bg-color)"}}>自行組成一班</span></li>
                                    <li><span style={{color: "var(--pink-bg-color)"}}>直接跟本中心相約上堂日期及時間</span></li>
                                    <li><span style={{color: "var(--pink-bg-color)"}}>最少4名學生成班</span></li>
                                    <li><span>學費	 ：</span>	$3200</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    );
};

export default Regular;