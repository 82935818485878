import React from 'react';

import classes from './PastEvents.module.css';

import Event1 from '@assets/images/past-events-talk-1.jpg';
import Event2 from '@assets/images/past-events-talk-2.jpg';
import Event3 from '@assets/images/past-events-talk-3.jpg';
import Event4 from '@assets/images/past-events-talk-4.jpg';
import Book1 from '@assets/images/past-events-book-1.jpg';
import Book2 from '@assets/images/past-events-book-2.jpg';

import AppleDaily from '@assets/medialogo/apple-daily-logo.png';
import Aristo from '@assets/medialogo/aristo-logo.png';
import HokYau from '@assets/medialogo/hok-yau-club-logo.png';
import Oriental from '@assets/medialogo/oriental-daily-logo.png';
import RTHK from '@assets/medialogo/rthk-logo.png';
import SingTao from '@assets/medialogo/sing-tao-logo.png';
import TheSun from '@assets/medialogo/the-sun-logo.png';

const PastEvents = (props) => {
    return (
        <React.Fragment>
        {/* <div className={classes.BackDrop}></div> */}
        <div className={classes.Photo}></div>
        {/* <img src={Backdrop} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/> */}
            <div className={classes.Body}>
                <div className={[classes.Container,"container"].join(" ")}>
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h1 className={classes.Title}>過往活動 / 傳媒</h1>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-12 col-lg-12", classes.MediaCol].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <p>本中心的顧問及導師過往曾就升學及學術事宜舉辦相關活動，亦有就相關議題與不同傳媒/機構合作及接受訪問︰</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={[classes.Col,"col-3 col-lg-3", classes.MediaCol].join(" ")}>
                            <div className={[classes.Card,"card h-100 text-center"].join(" ")}>
                                <div className={[classes.CardBody, classes.CardLogo,"card-body"].join(" ")}>
                                    <p className={classes.LogoBox}><img src={RTHK} alt="RTHK" className={classes.Media}/></p>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-5 col-lg-5", classes.MediaCol].join(" ")}>
                            <div className={[classes.Card,"card h-100 text-center"].join(" ")}>
                                <div className={[classes.CardBody, classes.CardLogo,"card-body"].join(" ")}>
                                    <p className={classes.LogoBox}><img src={SingTao} alt="SingTao" className={classes.Media}/></p>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-4 col-lg-4", classes.MediaCol].join(" ")}>
                            <div className={[classes.Card,"card h-100 text-center"].join(" ")}>
                                <div className={[classes.CardBody, classes.CardLogo,"card-body"].join(" ")}>
                                    <p className={classes.LogoBox}><img src={AppleDaily} alt="AppleDaily" className={classes.Media}/></p>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-6 col-lg-6", classes.MediaCol].join(" ")}>
                            <div className={[classes.Card,"card h-100 text-center"].join(" ")}>
                                <div className={[classes.CardBody, classes.CardLogo,"card-body"].join(" ")}>
                                    <p className={classes.LogoBox}><img src={Oriental} alt="Oriental" className={classes.Media2}/></p>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-6 col-lg-6", classes.MediaCol].join(" ")}>
                            <div className={[classes.Card,"card h-100 text-center"].join(" ")}>
                                <div className={[classes.CardBody, classes.CardLogo,"card-body"].join(" ")}>
                                    <p className={classes.LogoBox}><img src={TheSun} alt="TheSun" className={classes.Media2}/></p>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-5 col-lg-6", classes.MediaCol].join(" ")}>
                            <div className={[classes.Card,"card h-100 text-center"].join(" ")}>
                                <div className={[classes.CardBody, classes.CardLogo,"card-body"].join(" ")}>
                                    <p className={classes.LogoBox}><img src={HokYau} alt="HokYau" className={classes.Media}/></p>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-7 col-lg-6", classes.MediaCol].join(" ")}>
                            <div className={[classes.Card,"card h-100 text-center"].join(" ")}>
                                <div className={[classes.CardBody, classes.CardLogo,"card-body"].join(" ")}>
                                    <p className={classes.LogoBox}><img src={Aristo} alt="Aristo" className={classes.Media}/></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Row2, classes.Left, "row"].join(" ")}>
                        <div className={[classes.Col ,"col-12 col-lg-7"].join(" ")}>
                            <div className={[classes.Card2,"card"].join(" ")}>
                                <div className={["card-body"].join(" ")}>
                                    <p className={classes.CardTitle}>受多間小學邀請主持升中面試講座</p> 
                                    <img src={Event1} alt="Past Event Talk 1" className={[classes.Image,"img-fluid w-50"].join(" ")}/>
                                    <img src={Event2} alt="Past Event Talk 1" className={[classes.Image,"img-fluid w-50"].join(" ")}/>
                                    <img src={Event3} alt="Past Event Talk 1" className={[classes.Image,"img-fluid w-50"].join(" ")}/>
                                    <img src={Event4} alt="Past Event Talk 1" className={[classes.Image,"img-fluid w-50"].join(" ")}/>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col, classes.Hidden,"col-12 col-lg-5"].join(" ")}>
                            <div className={[classes.Card3,"card"].join(" ")}>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Row3,"row"].join(" ")}>
                        <div className={[classes.Col , classes.Hidden ,"col-12 col-lg-5"].join(" ")}>
                            <div className={[classes.Card3,"card"].join(" ")}>
                            </div>
                        </div>
                        <div className={[classes.Col ,"col-12 col-lg-7"].join(" ")}>
                            <div className={[classes.Card2,"card"].join(" ")}>
                                <div className={["card-body"].join(" ")}>
                                    <p className={classes.CardTitle}>星島日報升中天書 2015/16教授選校策略</p> 
                                    <img src={Book1} alt="Past Event Book 1" className={[classes.Image,"img-fluid w-50"].join(" ")}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Row4,"row"].join(" ")}>
                        <div className={[classes.Col ,"col-12 col-lg-7"].join(" ")}>
                            <div className={[classes.Card2,"card"].join(" ")}>
                                <div className={["card-body"].join(" ")}>
                                    <p className={classes.CardTitle}>星島日報升中天書 2017/18分享傳統名校面試形式及過往題目</p> 
                                    <img src={Book2} alt="Past Event Book 1" className={[classes.Image,"img-fluid w-50"].join(" ")}/>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col , classes.Hidden ,"col-12 col-lg-5"].join(" ")}>
                            <div className={[classes.Card3,"card"].join(" ")}>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Row5,"row"].join(" ")}>
                        <div className={[classes.Col , classes.Hidden ,"col-12 col-lg-5"].join(" ")}>
                            <div className={[classes.Card3,"card"].join(" ")}>
                            </div>
                        </div>
                        <div className={[classes.Col ,"col-12 col-lg-7"].join(" ")}>
                            <div className={[classes.Card4,"card"].join(" ")}>
                                <div className={["card-body"].join(" ")}>
                                    <p className={classes.CardTitle}>其他活動</p> 
                                    <ul className={classes.Points}>
                                        <li>東方日報採訪 2011</li>
                                        <li>太陽報採訪 2011</li>
                                        <li>蘋果日報採訪 2020 (升中面試)</li>
                                        <li>蘋果日報電話訪問 2021 (升中面試)</li>
                                        <li>派發「升中實用錦囊」小冊子</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                
            </div>
        </React.Fragment>
    );
};

export default PastEvents;