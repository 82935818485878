import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import * as RiIcons from 'react-icons/ri';

import classes from './SidebarLink.module.css';

const SidebarLink = (props) => {

    const[background, setBackground] = useState("var(--white-theme-color)");
    const[color, setColor] = useState("var(--dark-theme-color)");
    const[border, setBorder] = useState("1px solid rgb(177, 177, 177)");
    const[opacity, setOpacity] = useState("0");
    const[height, setHeight] = useState("0px");
    const[click, setClick] = useState(false);
    const item = props.item;

    const onClickHandler = () => {
        if (click) {
            setBackground("var(--white-theme-color)");
            setColor("var(--dark-theme-color)");
            setBorder("1px solid rgb(177, 177, 177)");
            setOpacity("0");
            setHeight("0");
            setClick(false);
        } else {
            setBackground("var(--dark-theme-color)");
            setColor("var(--gold-theme-color)");
            setBorder("none");
            setOpacity("1");
            setHeight("200px");
            setClick(true);
        }
    } 

    const onClickClear = () => {
        setBackground("var(--white-theme-color)");
        setColor("var(--dark-theme-color)");
        setBorder("1px solid rgb(177, 177, 177)");
        setOpacity("0");
        setHeight("0");
        setClick(false);
    }
    
    const onMouseEnter = () => {
        if(!click) {
            setColor("var(--gold-theme-color)");
        }
    }
    
    const onMouseLeave = () => {
        if (!click){
            setColor("var(--dark-theme-color)");
        }
    }


    const icon = (
        <React.Fragment>
            {click ? <RiIcons.RiArrowUpSLine/> : <RiIcons.RiArrowDownSLine/>}
        </React.Fragment>
    );

    
    const link = (
    <IconContext.Provider value={{size: '25px'}}>
        <Link
            to={item.subhead ? "#" : item.path}
            key={props.key}
            className={[classes.NavItem].join(" ")}
            style={{
                background: background,
                color: color,
                borderBottom: border,
                transition: "all 0.4s"
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-bs-dismiss={item.subhead ? "" : "offcanvas"}
            onClick={item.subhead ? onClickHandler : onClickClear}>
            

            {item.zhtitle}
            {item.subhead && icon}
        </Link>
    </IconContext.Provider>
    );

    const dropdown = 
    (<ul className={classes.DropItems}
        style={{
            maxHeight: height,
            opacity: opacity
        }}
        >
        {item.subhead && 
            item.subtitle.map((dropitem, index) => {
                return (
                    <Link to={dropitem.path} 
                    key={index+100}
                    className={[classes.DropItem]}
                    onClick={onClickClear} 
                    data-bs-dismiss="offcanvas">{dropitem.zhtitle}</Link>
                );
            })
        }
    </ul>);

    return (
        <React.Fragment>
            {link}
            {dropdown}
        </React.Fragment>
    );
};

export default SidebarLink;