import React from 'react';
import classes from './Application.module.css';
import AppForm from '@assets/application/premierscholars-application-form-2021.pdf';

const Application = (props) => {
    return (
        <React.Fragment>
            {/* <img src={Backdrop} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/> */}
            <div className={classes.Photo}></div>
            <div className={classes.Body}>
                <div className={[classes.Container,"container"].join(" ")}>
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h1 className={classes.Title}>報名</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h1 className={classes.Text}>請按連結下載表格︰
                                        <a href={AppForm} download>下載報名表格</a>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Row, "row"].join(" ")}>
                        {/* <div className={[classes.Col,"col-12 col-lg-6"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={["card-body"].join(" ")}>
                                    <p className={classes.TextTitle}>1) 支票︰</p>
                                    <p className={[classes.Text]}>請把填妥的<span>報名表格</span>連同<span>劃線支票</span>郵寄至「翰林薈」</p>
                                    <p className={[classes.Text]}>地址︰九龍佐敦柯士甸道102號6樓604-05室</p>
                                    <p className={[classes.Text]}>支票抬頭敬請寫上「<span>翰林薈教育中心</span>」</p>
                                    <p className={[classes.Text]}>請勿郵寄現金</p>
                                </div>
                            </div>
                        </div> */}
                        <div className={[classes.Col,"col-12 col-lg-6"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={["card-body"].join(" ")}>
                                    <p className={classes.TextTitle}>1)	親臨本中心︰</p>
                                    <p className={[classes.Text]}>請帶同填妥的報名表格，親臨「翰林薈」以
                                    <span>現金</span>
                                    {/* 或<span>支票方式</span> */}
                                    繳交學費。</p>
                                    <p className={[classes.Text]}>地址︰九龍佐敦柯士甸道102號6樓604-05室</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={["card-body"].join(" ")}>
                                    <p className={classes.TextTitle}>1)	親臨本中心︰</p>
                                    <p className={[classes.Text]}>請親臨「翰林薈」填妥的報名表格，並以
                                    <span>現金</span>或<span>支票方式</span>繳交學費。</p>
                                    <p className={[classes.Text]}>地址︰九龍佐敦柯士甸道102號6樓604-05室</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Application;