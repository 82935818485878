import React from 'react';
import classes from './AdvisoryTeam.module.css';

const AdvisoryTeam = (props) => {
    return (
        <React.Fragment>
        {/* <div className={classes.BackDrop}></div> */}
        <div className={classes.Photo}></div>
        {/* <img src={Backdrop} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/> */}
            <div className={classes.Body}>
                <div className={[classes.Container,"container .ZH"].join(" ")}>
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h1 className={classes.Title}>顧問團隊</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Row, "row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={["card-body"].join(" ")}>
                                    <p>翰林薈的顧問團隊包括傳統名校前教師，他們擁有教授會話課程的豐富經驗，部份更於學校任教期間負責入學面試環節，親身面見過不少學生。藉著他們的豐富經驗及寶貴意見，令翰林薈的升中配套更具質素。</p> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                            <div className={[classes.Card, classes.Card3,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h1 className={classes.Motto}>經驗 x 實力 x 質素</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Row, "row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-4"].join(" ")}>
                            <div className={[classes.Card2, classes.Card,"card h-100"].join(" ")}>
                                <div className={[classes.Teachers,"card-body"].join(" ")}>
                                    <h4 className=".EN">Mrs. Anne Chan</h4>
                                    <p><span>拔萃男書院</span></p>
                                    <p>前英文科主任</p>
                                    <p>時任入學面試負責老師之一</p>
                                    <p>超過30年教學經驗</p>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-12 col-lg-4"].join(" ")}>
                            <div className={[classes.Card2, classes.Card,"card h-100"].join(" ")}>
                                <div className={[classes.Teachers,"card-body"].join(" ")}>
                                    <h4>梁寶琛博士</h4>
                                    <p><span>香港華仁書院、</span></p>
                                    <p><span>九龍華仁書院</span></p>
                                    <p>前中國語文科專任老師</p> 
                                    <p>布萊頓大學教育學博士</p>
                                    <p>香港中文大學中國文學碩士</p>
                                    <p>超過30年教學經驗</p>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-12 col-lg-4"].join(" ")}>
                            <div className={[classes.Card2, classes.Card,"card h-100"].join(" ")}>
                                <div className={[classes.Teachers,"card-body"].join(" ")}>
                                    <h4 className=".EN">Miss. K. M. Cheung</h4>
                                    <p><span>德望學校、</span></p>
                                    <p><span>嘉諾撒聖瑪利學校</span></p>
                                    <p>前英文科老師</p>
                                    <p>具備教授英語會話的豐富經驗</p>
                                    <p>具有20年教學經驗</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </React.Fragment>
    );
};

export default AdvisoryTeam;