import React from 'react';

import classes from './EliteSchools.module.css';

import Chart from '@assets/background/chart-elite.jpg';

const Foundation = (props) => {
    return (
        <React.Fragment>
        {/* <div className={classes.BackDrop}></div> */}
        <div className={classes.Photo}></div>
        {/* <img src={Backdrop} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/> */}
        <div className={classes.Body}>
            <div className={[classes.Container,"container"].join(" ")}>
                <div className={[classes.Row,"row"].join(" ")}>
                    <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={[classes.CardBody,"card-body"].join(" ")}>
                                <h1 className={classes.Title}>名校專班</h1>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className={[classes.Row,"row"].join(" ")}>
                    <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={[classes.CardBody,"card-body"].join(" ")}>
                                <p className={classes.Text} style={{fontSize: "15.5px"}}>不同的中學有著不同的面試形式，名校面試專班的課程正正就著個別中學的面試形式作針對性的部署，協助學生更有效率準備應付目標中學的面試。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={Chart} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/>
                <div className={[classes.Row, "row"].join(" ")}>
                    <div className={[classes.Col,"col-12 col-xl-12"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={["card-body"].join(" ")}>
                                <p className={classes.TextTitle}>課程特色︰</p>
                                <ul className={[classes.List, classes.Text].join(" ")}>
                                    <li>以個別中學的面試形式為課程大綱，其針對性部署更為見效</li>
                                    <li>列舉不同面試形式的取分技巧，讓學生面試時更得心應手</li>
                                    <li>分析過往試題（Past Questions），協助學生更熟識目標中學面試形式及題型</li>
                                    <li>以過往試題（Past Questions）為練習，快速掌握目標中學的面試竅門</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Col,"col-12 col-xl-12"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={["card-body"].join(" ")}>
                                <p className={classes.TextTitle}>其他資料：</p>
                                <ul className={[classes.List2, classes.Text].join(" ")}>
                                    <li><span>對象 ︰</span> 已完成升中面試常規班的同學</li>
                                    <li><span>教學語言 ：</span> 英語 & 廣東話</li>
                                    <li><span>師生比例 ：</span> 最多6人一班</li>
                                    <li><span>學費	 ：</span>	$1200</li>
                                    <li><span style={{color: "var(--pink-bg-color)"}}>課程共3堂，每堂1小時30分鐘 (包每名學生一本指定教材)</span></li>					
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className={[classes.Col,"col-12 col-xl-12"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={["card-body"].join(" ")}>
                                <p className={[classes.TextTitle, classes.TextTitle2].join(" ")} 
                                    style={{
                                        background: "var(--dark-theme-color)", 
                                        color: "var(--gold-theme-color)",
                                        textAlign: "center"
                                    }}>以往曾開辦過的<span>名校面試專班包括︰</span></p>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Col,"col-12 col-xl-6"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={["card-body"].join(" ")}>
                                <p className={classes.TextTitle}
                                    style={{
                                        background: "rgb(181, 181, 255)", 
                                        color: "var(--dark-theme-color)",
                                        textAlign: "center"
                                    }}>直資中學︰</p>
                                <ul className={[classes.List5, classes.Text5].join(" ")}>
                                    <li>聖保羅男女中學 <span>(St. Paul's Co-Educational College)</span></li>
                                    <li>拔萃女書院 <span>(Diocesan Girls' School)</span></li>
                                    <li>拔萃男書院 <span>(Diocesan Boys' School)</span></li>
                                    <li>協恩中學 <span>(Heep Yunn School)</span></li>
                                    <li>德望學校 <span>(Good Hope School)</span> <p style={{fontSize: "11px", marginBottom: "0px"}}>*內部面試 (適合德望小學同學)</p></li>
                                    <li>德望學校 <span>(Good Hope School)</span> <p style={{fontSize: "11px", marginBottom: "0px"}}>*公開面試 (適合非德望小學同學)</p></li>
                                    <li>聖保祿學校 <span>(St. Paul's Convent School)</span></li>
                                    <li>聖保羅書院 <span>(St. Paul's College)</span></li>
                                    <li>英華書院 <span>(Ying Wa College)</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Col,"col-12 col-xl-6"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={["card-body"].join(" ")}>
                                <p className={classes.TextTitle}
                                    style={{
                                        background: "rgb(181, 181, 255)", 
                                        color: "var(--dark-theme-color)",
                                        textAlign: "center"
                                    }}>官立/ 津貼中學︰</p>
                                <ul className={[classes.List5, classes.Text5].join(" ")}>
                                    <li>皇仁書院 <span>(Queen's College)</span></li>
                                    <li>喇沙書院 <span>(LaSalle College)</span></li>
                                    <li>聖若瑟書院 <span>(St. Joseph's College)</span></li>
                                    <li>聖士提反女子中學 <span>(St. Stephen's Girls' College)</span></li>
                                    <li>英華女學校 <span>(Ying Wa Girls' School)</span></li>
                                    <li>瑪利諾修院學校 (中學部) <span>(Maryknoll Convent School Secondary Section)</span></li>
                                    <li>英皇書院 <span>(King's College)</span></li>
                                    <li>九龍華仁書院 <span>(Wah Yan College Kowloon)</span></li>
                                    <li>庇理羅士女子中學 <span>(Belilios Public School)</span></li>
                                    <li>寶血會上智英文書院 <span>(Holy Trinity College)</span></li>
                                    <li>真光女書院 <span>(True Light Girls' College)</span></li>
                                    <li>伊利沙伯中學 <span>(Queen Elizabeth School)</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    );
};

export default Foundation;