import React, { useState } from "react";
import {Link} from 'react-router-dom';

import classes from "./Portal.module.css";
import "./Global.css";

import Logo from "@assets/logo/logo-white.png";

const Portal = (props) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [authError, setAuthError] = useState(false);
	const [showPW, setShowPW] = useState();

	let type = "password";
	if(username);
	if (showPW) {
		type = "text";
	} else {
		type = "password";
	}

	const onSubmitHandler = () => {
		setAuthError(true);
		setPassword("");
	};

	const errorMsgHidden = (
		<div className={classes.ErrorMsg} style={{visibility: "hidden"}}>
			<p className={classes.Msg}>Invalid username/password</p>
			<p className={classes.Msg}>用戶名稱/密碼不正確</p>
		</div>
	);

	const errorMsg = (
		<div className={classes.ErrorMsg}>
			<p className={classes.Msg}>Invalid username/password</p>
			<p className={classes.Msg}>用戶名稱/密碼不正確</p>
		</div>
	);


	return (
		<React.Fragment>
			<div className={classes.body}>
				<div className="l-form">
					<div action="/portal" className="form">
						<Link to="/">
							<img
								alt="Premier Scholars Logo"
								className={classes.Logo}
								src={Logo}
							/>
						</Link>
						
						<h4 className="form__title">Web Portal 網上平台</h4>
						<div className="form__div">
							<input
								type="text"
								className="form__input"
								placeholder=" "
								autoFocus
								onChange={(event) => {
									setAuthError(false);
									setUsername(event.target.value);
								}}
								onClick={() => setAuthError(false)}
							/>
							<label className="form__label">Username 用戶名稱</label>
						</div>
						<div className="form__div">
							<input
								type={type}
								className="form__input"
								placeholder=" "
								value={password}
								onChange={(event) => {
									setAuthError(false);
									setPassword(event.target.value);
								}}
								onClick={() => setAuthError(false)}
							/>
							<label className="form__label">Password 密碼</label>
						</div>
						<div className={classes.CheckBoxContainer}>
							<input
								className={classes.CheckBox}
								type="checkbox"
								onClick={() => setShowPW(!showPW)}
							/>
							<label className={classes.ShowPW}>Show Password 顯示密碼</label>
						</div>
						{authError ? errorMsg : errorMsgHidden}
						<input
							type="submit"
							className="form__button"
							value="Login  登入"
							onClick={onSubmitHandler}
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Portal;
