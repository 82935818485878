import React from 'react';
//import classes from './Partnerships.module.css';

const Partnerships = (props) => {
    return (
        <React.Fragment>
            <h1>Partnerships Page</h1>
        </React.Fragment>
    );
};

export default Partnerships;