import React from 'react';

import classes from './Foundation.module.css';

import Chart from '@assets/background/chart-foundation.jpg';

const Foundation = (props) => {
    return (
        <React.Fragment>
        {/* <div className={classes.BackDrop}></div> */}
        <div className={classes.Photo}></div>
        {/* <img src={Backdrop} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/> */}
        <div className={classes.Body}>
            <div className={[classes.Container,"container .ZH"].join(" ")}>
                <div className={[classes.Row,"row"].join(" ")}>
                    <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={[classes.CardBody,"card-body"].join(" ")}>
                                <h1 className={classes.Title}>升中面試基礎班<span className={classes.Grade}>（小四 – 小五）</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={Chart} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/>
                <div className={[classes.Row, "row"].join(" ")}>
                    <div className={[classes.Col,"col-12 col-xl-6"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={["card-body"].join(" ")}>
                                <p className={classes.TextTitle}>課程簡介︰</p>
                                <ul className={[classes.List, classes.Text].join(" ")}>
                                    <li>以升中面試技巧為本的會話課程</li>
                                    <li>由淺入深教導學生基本面試技巧，提早掌握面試成功要訣</li>
                                    <li>學習更多英語詞彙，並練習不同情境的會話句式及表達技巧</li>
                                    <li>完成後可銜接升中面試常規班</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Col,"col-12 col-xl-6"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={["card-body"].join(" ")}>
                                <p className={classes.TextTitle}>課程大綱：</p>
                                <ul className={[classes.List3, classes.Text].join(" ")}>
                                    <li>英語說話︰個人、校園與社會</li>
                                    <li>社會議題討論</li>
                                    <li>朗讀文章</li>
                                    <li>廣東話小組討論</li>					
                                    <li>英語小組討論</li>		
                                    <li>模擬面試</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Col,"col-12 col-xl-12"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={["card-body"].join(" ")}>
                                <p className={classes.TextTitle}>其他資料：</p>
                                <ul className={[classes.List2, classes.Text].join(" ")}>
                                    <li><span>對象 ︰</span> 小四、小五學生</li>
                                    <li><span>教學語言 ：</span> 英語 & 廣東話</li>
                                    <li><span>師生比例 ：</span> 最多6人一班</li>
                                    <li><span>學費	 ：</span>	$2800</li>
                                    <li><span style={{color: "var(--pink-bg-color)"}}>課程共8堂，每堂1小時30分鐘 (包每名學生一本指定教材)</span></li>					
                                </ul>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </React.Fragment>
    );
};

export default Foundation;