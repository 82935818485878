import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Carousel.module.css';
import '@components/Global.css';

import Slide1 from '@assets/slides/slide1.jpg';
import Slide2 from '@assets/slides/slide2.jpg';
import Slide3 from '@assets/slides/slide3.jpg';
import Slide4 from '@assets/slides/slide4.jpg';
import Slide5 from '@assets/slides/slide5.jpg';

const Carousel = (props) => {

    return (
        <React.Fragment>
            <div className={[classes.Carousel,""].join(" ")}>
                <div 
                    id="carouselExampleIndicators" 
                    className="carousel slide" 
                    data-bs-ride="carousel"
                    data-bs-interval="3000">
                    <div className={[classes.Button, "carousel-indicators"].join(" ")}>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    </div>
                    <div className="carousel-inner" >
                        <div className="carousel-item active">
                            <Link to="/regular"><img src={Slide1} className="d-block w-100" alt="Interview Courses"/></Link> 
                        </div>
                        <div className="carousel-item">
                            <Link to="/elite-schools"><img src={Slide2} className="d-block w-100" alt="Elite Schools Course"/></Link> 
                        </div>
                        <div className="carousel-item">
                            <Link to="/group-discussion"><img src={Slide3} className="d-block w-100" alt="Group Discussion Course"/></Link> 
                        </div>
                        <div className="carousel-item">
                            <Link to="/foundation"><img src={Slide4} className="d-block w-100" alt="Foundation Course"/></Link> 
                        </div>
                        <div className="carousel-item">
                            <Link to="/regular"><img src={Slide5} className="d-block w-100" alt="All-rounded Preparation"/></Link> 
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            
            
        </React.Fragment>
    );
};

export default Carousel;

