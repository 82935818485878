import React from 'react';
import {Link} from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import * as IoIcons from 'react-icons/io5';

import classes from './Sidebar.module.css';
import '@components/Global.css';

import Logo from '@assets/logo/logo-white.png';
import NavLinks from '@assets/navlinks/navlink';
import SidebarLink from './SidebarLink/SidebarLink';

const Sidebar = (props) => {  
    
    const navlinks = NavLinks.map((item, index) => {
        return(
            <SidebarLink key={index} item={item}/>
        );
    })

    return (
        <React.Fragment>
            <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className={[classes.Header,"offcanvas-header" ].join(" ")}>
                <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                    <Link to="/">
                        <img
                        alt="Premier Scholars Logo"
                        height="60px"
                        style={{marginTop: "5px"}}
                        src={Logo}
                        data-bs-dismiss="offcanvas"
                        />
                    </Link>
                </h5>
                <IconContext.Provider value={{size: '40px'}}>
                    <IoIcons.IoCloseSharp
                        data-bs-dismiss="offcanvas" 
                        aria-label="Close"
                        className={classes.CloseBtn}
                    />
                </IconContext.Provider>
            </div>
            <div className={["offcanvas-body", classes.MenuBody].join(" ")}>
                <ul className={[classes.NavItems, '.ZH'].join(' ')}>
                    {navlinks}
                </ul>
                <div className={[classes.Container, '.ZH'].join(' ')}>
                    <Link to="/application">
                        <button type="button" data-bs-dismiss="offcanvas" className={[classes.NavButton, "btn btn-primary"].join(" ")}>報名</button>
                    </Link>
                    <Link to="/portal">
                        <button type="button" data-bs-dismiss="offcanvas" className={[classes.NavButton, classes.Portal, "btn btn-primary"].join(" ")}>網上平台</button>
                    </Link>
                </div>
            </div>
            </div>
        </React.Fragment>
    );
};

export default Sidebar;