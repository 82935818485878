import React from 'react';
import classes from './OnSite.module.css';

const OnSite = (props) => {
    return (
        <React.Fragment>
            {/* <div className={classes.BackDrop}></div> */}
            <div className={classes.Photo}></div>
            <div className={classes.Body}>
                <div className={[classes.Container,"container .ZH"].join(" ")}>
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={["col-12 col-lg-5", classes.Col].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h1 className={classes.Title}>到校服務</h1>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-12 col-lg-7"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                
                                <div className={[classes.Paragraph,"card-body"].join(" ")}>
                                <p>一站式小學到校課程 歡迎查詢任何課程</p> 
                                    <br/>
                                <p>聯絡地址：新界荃灣荃景圍８７－１０５號荃灣中心１期商場地下高層Ｃ5號舖</p>
                                    <br/>
                                <p>聯絡號碼：9682 3425 / 6760 1792</p>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
        </React.Fragment>
    );
};

export default OnSite;