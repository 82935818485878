import React from 'react';
import {Link} from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import * as RiIcons from 'react-icons/ri';

import classes from './Topbar.module.css';

import NavLink from '@assets/navlinks/navlink';

const Topbar = (props) => {

    const navList = NavLink.map((item, index) => {
        return (
        <div className={classes.Dropdown} key={index}>
            <Link key={index+1} to={item.path} className={classes.Link}>
                <li key={index+2}
                    className={[classes.NavItem].join(" ")}
                    style={{padding: props.padding}}>
                    {item.zhtitle}
                    {item.subhead && (
                        <IconContext.Provider value={{ size: '25px' }}>
                            <RiIcons.RiArrowDownSLine className={classes.Icon}/>
                        </IconContext.Provider>
                    )}
                </li>
            </Link>
            {item.subhead && (
                <ul className={[classes.DropdownList].join(" ")}
                    style={{top: props.navSize}}
                    key={index+3}>
                    {item.subtitle.map((subitem, subindex) => {
                        return (
                            <Link key={subindex+4} to={subitem.path} className={classes.Sublink}>
                                <li key={subindex+5}
                                    className={classes.DropItem}>
                                    {subitem.zhtitle}
                                </li>
                            </Link>
                        );
                    })}
                </ul>
            )}
        </div>            
        );
    });

    return (
        <React.Fragment>
            <ul className={[classes.NavItems, '.ZH'].join(' ')}>
                {navList}
            </ul>
            <Link to="/application">
                <button type="button" className={[classes.NavButton, "btn btn-primary"].join(" ")}>報名</button>
            </Link>
            <Link to="/portal">
                <button type="button" className={[classes.NavButton, classes.Portal, "btn btn-primary"].join(" ")}>網上平台</button>
            </Link>
        </React.Fragment>
    );
};

export default Topbar;