import React from 'react';
import classes from './Timetable.module.css';

const Timetable = (props) => {
    return (
        <React.Fragment>
        <div className={classes.Photo}></div>
        <div className={classes.Body}>
            <div className={[classes.Container,"container"].join(" ")}>
                <div className={[classes.Row,"row"].join(" ")}>
                    <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={[classes.CardBody,"card-body"].join(" ")}>
                                <h1 className={classes.Title}>常規班時間表</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={[classes.Row,"row"].join(" ")}>
                    <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={[classes.CardBody,"card-body"].join(" ")}>
                                <p className={classes.Text}>如欲查詢最新時間表，請致電或WhatsApp 63291282。</p>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                        <div className={[classes.Card,"card"].join(" ")}>
                            <div className={[classes.CardBody,"card-body"].join(" ")}>
                            <iframe title="timetable" className={[classes.Iframe,""].join(" ")} src="https://docs.google.com/spreadsheets/d/e/2PACX-1vS7Q-20NS74JtgignIXtnxYnYmq3UO9KecHIkx-xNg86DoLF_tOcVDdWpEuXuovpKc7sYJMkHHLR9ay/pubhtml?chrome=false&amp;widget=false&amp;headers=false"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    );
};

export default Timetable;