import React, { useEffect } from 'react';
import { withRouter} from 'react-router-dom';


const Error404 = (props) => {

    useEffect(() => {
        props.history.replace(props.link);
    },[props]);

    return (
        <React.Fragment>
        </React.Fragment>
    );
};

export default withRouter(Error404);