import React from 'react';
import ImpDates from '@components/Table/DatesTable.js';
import classes from './ImpDates.module.css';

const Timetable = (props) => {
    return (
        <React.Fragment>
        <div className={classes.Photo}></div>
        <div className={classes.Body}>
            <div className={[classes.Container,"container"].join(" ")}>
                <ImpDates/>
            </div>
        </div>
    </React.Fragment>
    );
};

export default Timetable;