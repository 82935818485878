import React from 'react';
import './App.css';

// import Logo from './assets/logo/logo-blue.png';
// import HomePage from './pages/HomePage/HomePage';
//import Portal from './pages/Portal/Portal';
import {  BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Error404 from '@views/Error404/Error404';
import Main from '@views/Main/Main';
import Portal from '@views/Portal/Portal';


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/portal" component={Portal} />
        <Route path="/" component={Main} />
        <Route component={() => <Error404 link="/"/>} />
      </Switch>
    </Router>
  );
}

export default App;