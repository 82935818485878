import React from 'react';
import classes from './AboutUs.module.css';

const AboutUs = (props) => {
    return (
        <React.Fragment>
            {/* <div className={classes.BackDrop}></div> */}
            <div className={classes.Photo}></div>
            <div className={classes.Body}>
                <div className={[classes.Container,"container .ZH"].join(" ")}>
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={["col-12 col-lg-5", classes.Col].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h1 className={classes.Title}>關於我們</h1>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-12 col-lg-7"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                
                                <div className={[classes.Paragraph,"card-body"].join(" ")}>
                                <p>翰林薈是一所專辦升中面試課程的教育機構。教學團隊擁有超過10年專門教授升中面試課程經驗，熟識香港升中機制及不同中學的面試形式，揉合兩方面相關的知識及經驗，設計出一系列的面試培訓課程，為即將升中的學生做全方位的準備，以提升獲得取錄的機會。</p> 
                                    <br/>
                                <p>而憑藉專業的課程與教學，教學團隊已於過去十多年協助過千名學生成功入讀目標中學，當中不乏深受家長歡迎的傳統名校及直資名校。</p>
                                    <br/>
                                <p>此外，翰林薈的顧問團隊更包括傳統名校前教師，於學校任教期間曾負責入學面試環節，親身考核過不少學生及見證不同的面試情況。藉著顧問老師所提供的寶貴意見，翰林薈的升中面試課程將一於既往為學生帶來莫大裨益。</p>
                                    <br/>
                                <p>我們深信翰林薈所提供的一系列的升中配套，必定能協助家長及學生為升中做好準備，向心儀學府進發！</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
        </React.Fragment>
    );
};

export default AboutUs;