import React from 'react';
import {Link} from 'react-router-dom';
import classes from './DatesTable.module.css';

const DatesTable = (props) => {
    return (
        <React.Fragment>
            <div className={[classes.CardBody,"card-body"].join(" ")}>
                <h1 className={classes.Title}>重要日期</h1>
            </div>
            <div className="table-responsive">
                <table className="table">
                <thead>
                    <tr>
                        <th className={[classes.TR, ""].join("")} scope="col"></th>
                        <th className={[classes.TH, ""].join("")} scope="col">學校名稱</th>
                        <th className={[classes.TH, ""].join("")} scope="col">活動內容</th>
                        <th className={[classes.TH, ""].join("")} scope="col">日期</th>
                        <th className={[classes.TH, ""].join("")} scope="col">備註</th>
                        <th className={[classes.TH, ""].join("")} scope="col">連結</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">1</th>
                        <td>拔萃女書院</td>
                        <td>中一入學講座</td>
                        <td>9月21日</td>
                        <td>10:00 - 12:00</td>
                        <td><a href="https://www.dgs.edu.hk/admissions/s1-application-chi" target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">2</th>
                        <td>德望學校</td>
                        <td>中一入學講座和參觀</td>
                        <td>9月28日</td>
                        <td>8:30 - 10:30</td>
                        <td><a href="https://www2.ghs.edu.hk/application/login" target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <td>協恩中學</td>
                        <td>中一入學講座</td>
                        <td>10月12日</td>
                        <td>10:00 - 16:30</td>
                        <td><a href="http://www.hys.edu.hk/form-one-admission-2025-26/" target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">4</th>
                        <td>聖保羅男女中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.spcc.edu.hk/chi/admissions/local-admissions/secondary/f1" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">5</th>
                        <td>拔萃男書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.dbs.edu.hk/index.php?s=admission&m=g7app" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">6</th>
                        <td>香港華仁書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSd4v-jYC6NKiKhMBLgS-ABiy9eligMCqiBRikjvHHmsh7QBmg/closedform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">7</th>
                        <td>喇沙書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://us06web.zoom.us/webinar/register/WN_U17I-HQTQ2mU0_Kd0WGYOA" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">8</th>
                        <td>聖保羅書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="http://www.spc.edu.hk/content.php?id=156&mid=70-103" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">9</th>
                        <td>華仁書院（九龍）</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://wyk.edu.hk/academic/admission/s1-admissions-0/" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">10</th>
                        <td>聖保祿學校</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="http://www.spcs.edu.hk/admission/s1admission.htm" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">11</th>
                        <td>東華三院黃笏南中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.twghwfns.edu.hk/wfnchp/f1_admission/admission_chi_2223.pdf" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">12</th>
                        <td>德信中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSdwRe_lA0xe0kua3f4GgGk2ev2rDTJXj5Hl0PXymvQeUthsmA/viewform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">13</th>
                        <td>香港真光中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSedNSeYqA2uZLNEovMMuYI8NxTmg23Wp3gIMaKLt0a3e3YAAg/viewform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">14</th>
                        <td>聖芳濟書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="http://www.sfxc.edu.hk/index.php/events/school/announcement/675-information-day" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">15</th>
                        <td>英華書院 </td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.yingwa.edu.hk/index.php?s=3_48" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">16</th>
                        <td>聖羅撒書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSfhMlFfPiuuHzieL4ols_5enGNjFPur5IEiD1Hin-80rHNiRw/closedform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">17</th>
                        <td>長沙灣天主教英文中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="http://sys.cswcss.edu.hk/P6F1/" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">18</th>
                        <td>藍田聖保祿中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.spslt.edu.hk/s1-interview-list" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">19</th>
                        <td>聖言中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://sites.google.com/singyin.edu.hk/f1-info-day?pli=1" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">20</th>
                        <td>九龍真光中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSenJdx8BZHrZH-eJ0cdTGwjjd5KTZ1qHleGDRtfspomDosi-w/viewform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">21</th>
                        <td>聖瑪加利男女英文中小學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.smcesps.edu.hk/en/?p=admission_s1" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">22</th>
                        <td>藍田聖保祿中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.spslt.edu.hk/s1-interview-list" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">23</th>
                        <td>聖母無玷聖心書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSfNI4BLJEA_-C3iw6DlaJBQu7jMI-0AF73sK20EP7g9kY--vg/closedform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">24</th>
                        <td>禮賢會彭學高紀念中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSeAP9_eEOfNwKPFznejJykYRaOpHffczCmW8bXyxE5xteQmMA/viewform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">25</th>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSeKusWDSqyrFoNVRnounUgXfAaUi7b_wmBs_OF3znAY0ars5w/viewform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">26</th>
                        <td>保良局羅氏基金中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="http://www.plklfc.edu.hk/index.php?option=com_content&view=article&id=592&Itemid=396&lang=zh" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">27</th>
                        <td>德愛中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSelxwwUAqu4TswhMhGLJ5GXs9UyKaSNIlYmx7FDIH3mUSL1kw/viewform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">28</th>
                        <td>基督教宣道會宣基中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSeQd2lIuZaqjjEMiZkhoXXG_DThfNMm0kYSBTX4SLuVK9M_fg/viewform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">29</th>
                        <td>基督教中國佈道會聖道迦南書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://stcc.edu.hk/en/2023-2024-s1-admission" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">30</th>
                        <td>香港浸會大學附屬學校王錦輝中小學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://psadmission.hkbuas.edu.hk/seminar/ss/reserve.php" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">31</th>
                        <td>瑪利諾神父教會學校</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSenQIdPEILkBpdWgHitF5ObGzsRBRa928lOcaTJtSaIZwq7-g/closedform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">32</th>
                        <td>可立中學(嗇色園主辦)</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSfg3md9yJtDLD-QJ8hQ7fStD1k74oOmHFE7wKE6D9bnJx2CIA/viewform?fbzx=6569456820414108610" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">33</th>
                        <td>保良局羅氏基金中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="http://www.plklfc.edu.hk/index.php?option=com_content&view=article&id=1144&Itemid=84&lang=zh" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">34</th>
                        <td>聖傑靈女子中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="http://www.scsg.edu.hk/Openday2022.html" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">35</th>
                        <td>保良局顏寶鈴書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.npl.edu.hk/en/site/view?name=1+Admission+23-24" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">36</th>
                        <td>天主教郭得勝中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.ktscss.edu.hk/s1-discretionary-places-allocation-2023-2024" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">37</th>
                        <td>聖文德書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLScILOMkA0EK3uedTc6-RZT3qLUjEpZB7lHuOBkULuF7cViL6w/viewform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">38</th>
                        <td>中華基督教會協和書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://ccchwc.edu.hk/" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">39</th>
                        <td>迦密中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.carmelss.edu.hk/site/about-school/carmel-information-day-2022/" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">40</th>
                        <td>德雅中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://forms.office.com/pages/responsepage.aspx?id=B0famt3EYUaKPq_S4rKE8lXO6ilZGm1AuK_QKzmsJtFURUhUMVBaSkdCMFlQVlg2NzZOTVNTNzdPUC4u" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">41</th>
                        <td>香島中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLScj8vyFsgGJfiWiEv9fOy-LvXwZCV8lYEQxq1Ucq4seD91zOw/viewform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">42</th>
                        <td>中華基督教會蒙民偉書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSen8304jU_7O6PPRwhC1k6z1DZWZ4FPMitezztC_al3bvwhYg/closedform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">43</th>
                        <td>萬鈞匯知中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSdnDYew6KTYUKMVX5OBgCxXfubMGwU_f7zb7SKRPdFewe1InA/viewform" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">44</th>
                        <td>福建中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.fms.edu.hk/p/37584?lang=zh" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">45</th>
                        <td>華英中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="http://www.waying.edu.hk/MainSite/Admission.php?cid=62#Chin" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">46</th>
                        <td>炮台山循道衛理中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.lovefhmss.org/_files/ugd/a0736e_f15e199703ac4008ab090dfe9f568550.pdf" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">47</th>
                        <td>保良局何蔭棠中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.plkchc.edu.hk/" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">48</th>
                        <td>中華基金中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://cfss.ievent.hk/event/3052" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">49</th>
                        <td>香港神託會培基書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://eadmission.spkc.edu.hk/" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">50</th>
                        <td>浸信會呂明才中學</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.blmcss.edu.hk/main/zh_tw/site/view?name=S1+Admission+2023%2F24" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>
                    <tr>
                        <th scope="row">51</th>
                        <td>保良局顏寶鈴書院</td>
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                        <td><a href="https://www.npl.edu.hk/en/site/view?name=1+Admission+23-24" className={classes.Disabled} target="_blank" rel="noopener noreferrer">按此</a></td>
                    </tr>




                </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default DatesTable;