import React from 'react';

const Bridging = (props) => {
    return (
        <React.Fragment>
            <h1>Bridging Page</h1>
        </React.Fragment>
    );
};

export default Bridging;