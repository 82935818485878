import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Switch, Route} from 'react-router-dom';

import classes from './Main.module.css';

import NavBar from '@components/NavBar/NavBar';
import Footer from '@components/Footer/Footer';

import AboutUs from "./AboutUs/AboutUs";
import AdvisoryTeam from "./AdvisoryTeam/AdvisoryTeam";
import VisionMissions from "./VisionMissions/VisionMissions";
import PastEvents from "./PastEvents/PastEvents";
import Partnerships from "./Partnerships/Partnerships";
// import Courses from './Courses/Courses';
import Foundation from "./Foundation/Foundation";
import Regular from "./Regular/Regular";
import EliteSchools from "./EliteSchools/EliteSchools";
import EnglishSpeaking from "./EnglishSpeaking/EnglishSpeaking";
import GroupDiscussion from "./GroupDiscussion/GroupDiscussion";
import AdmissionTalk from "./AdmissionTalk/AdmissionTalk";
import Bridging from "./Bridging/Bridging";
import Timetable from "./Timetable/Timetable";
import Application from "./Application/Application";
import ContactUs from "./ContactUs/ContactUs";
import Home from './Home/Home';
import Error404 from '../Error404/Error404';
import ImpDates from './ImpDates/ImpDates';
import OnSite from './OnSite/OnSite';
//import Test from '../TestPage/TestPage'



const Main = (props) => {

    const[marginSize, setMarginSize] = useState("100px");
    
    const listenScrollEvent = () => {
        window.scrollY > 6 ? setMarginSize("80px") : setMarginSize("100px");
    }

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
        return () => {
            window.removeEventListener("scroll", listenScrollEvent);
        };
    }, []);

    return (
        <React.Fragment>
            {ReactDOM.createPortal(<NavBar/>, document.getElementById("navbar-root"))}
            <div 
                    className={classes.Pages}
                    style={{
                        marginTop: marginSize,
                         transition: "all 1s"
                }}>
                
                <Switch>
                    <Route path='/about-us' component={AboutUs}/>
                    <Route path='/advisory-team' component={AdvisoryTeam}/>
                    <Route path='/vision-missions' component={VisionMissions}/>
                    <Route path='/past-events' component={PastEvents}/>
                    <Route path='/partnerships' component={Partnerships}/>
                    {/* <Route path='/courses' component={Courses}/> */}
                    <Route path='/foundation' component={Foundation}/>
                    <Route path='/regular' component={Regular}/>
                    <Route path='/elite-schools' component={EliteSchools}/>
                    <Route path='/english-speaking' component={EnglishSpeaking}/>
                    <Route path='/group-discussion' component={GroupDiscussion}/>
                    <Route path='/f1-admission-talk' component={AdmissionTalk}/>
                    <Route path='/f1-bridging' component={Bridging}/>
                    <Route path='/timetable' component={Timetable}/>
                    <Route path='/application' component={Application}/>
                    <Route path='/contact-us' component={ContactUs}/>
                    <Route path='/information' component={ImpDates}/>
                    <Route path='/important-dates' component={ImpDates}/>
                    <Route path='/onsite' component={OnSite}/>
                    <Route path='/' exact component={Home}/>
                    <Route component={()=> <Error404 link="/"/>}/>
                </Switch>
            </div>
            <Footer/>
        </React.Fragment>
    );
};

export default Main;