
const NavLinks = [
    {
        zhtitle: '關於我們',
        path: '/about-us',
        subhead: true,
        subtitle: [
            {
                zhtitle: '關於我們',
                path: '/about-us'
            },
            {
                zhtitle: '顧問團隊',
                path: '/advisory-team'
            },
            {
                zhtitle: '願景及使命',
                path: '/vision-missions'
            },
            {
                zhtitle: '過往活動 / 傳媒',
                path: '/past-events'
            }
            // ,
            // {
            //     zhtitle: '合作伙伴',
            //     path: '/partnerships'
            // }
        ]
    },
    {
        zhtitle: '到校課程',
        path: '/onsite',
        subhead: false
    },
    {
        zhtitle: '升中面試課程',
        path: '/regular',
        subhead: true,
        subtitle: [
            {
                zhtitle: '升中面試基礎班（小四 – 小五）',
                path: '/foundation'
            },
            {
                zhtitle: '升中面試常規班（小五 – 小六）',
                path: '/regular'
            },
            {
                zhtitle: '名校專班',
                path: '/elite-schools'
            }
        ]
    },
    {
        zhtitle: '升中配套課程 / 服務 ',
        path: '/english-speaking',
        subhead: true,
        subtitle: [
            {
                zhtitle: '面試英語會話班',
                path: '/english-speaking'
            },
            {
                zhtitle: '小組討論技巧訓練班',
                path: '/group-discussion'
            }
            ,
            {
                zhtitle: '全方位升中資訊講座',
                path: '/f1-admission-talk'
            }
            // ,
            // {
            //     zhtitle: '升中銜接課程',
            //     path: '/f1-bridging'
            // }
        ]
    }
    ,
    {
        zhtitle: '升中資訊',
        path: '/information',
        subhead: true,
        subtitle: [{
            zhtitle: '重要日期',
            path: '/important-dates'
        }]
    },
    {
        zhtitle: '課程時間表',
        path: '/timetable',
        subhead: false,
        subtitle: [{
            zhtitle: '',
            path: ''
        }]
    }
    // ,
    // {
    //     zhtitle: '報名',
    //     path: '/application',
    //     subhead: false,
    //     subtitle: [{
    //         zhtitle: '',
    //         path: ''
    //     }]
    // }
    ,
    {
        zhtitle: '聯絡我們',
        path: '/contact-us',
        subhead: false,
        subtitle: [{
            zhtitle: '',
            path: ''
        }]
    }
]

export default NavLinks;