import React from 'react';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import * as AiIcon from 'react-icons/ai';
import * as BiIcon from 'react-icons/bi';
import * as FiIcon from 'react-icons/fi';
import * as SiIcon from 'react-icons/si';

import classes from './Home.module.css';

import Carousel from '@components/Carousel/Carousel';
import CarouselMobile from '@components/CarouselMobile/CarouselMobile';
import ContactUs from '../ContactUs/ContactUs';
import Schoollogo from '@assets/background/school-logo-1.jpg';
import ImpDateTable from '@components/Table/DatesTableShort.js';



const Home = (props) => {

    return (
        <React.Fragment>
        <div className={classes.Body}>
            <Carousel/>
            <CarouselMobile/>
            <div className={classes.SchoolLogo}>
                <img src={Schoollogo} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/>
            </div>
            <IconContext.Provider value={{}} >
            <div className={[classes.Container,"container"].join(" ")}>
                <div className={[classes.Row,"row"].join(" ")}>
                    <div className={[classes.Col,"col-6 col-sm-6 col-md-3"].join(" ")}>
                        <Link to="/regular">
                            <div className={[classes.Card,"card"].join(" ")}>
                                <h5 className="card-title"><SiIcon.SiGoogleclassroom className={classes.Icon}/></h5>
                                <div className={[classes.CardBody, classes.IconTitle,"card-body"].join(" ")}>
                                    升中面試課程
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className={[classes.Col,"col-6 col-sm-6 col-md-3"].join(" ")}>
                        <Link to="/english-speaking">
                            <div className={[classes.Card,"card"].join(" ")}>
                                <h5 className="card-title"><BiIcon.BiBookAlt className={classes.Icon}/></h5>
                                <div className={[classes.CardBody, classes.IconTitle,"card-body"].join(" ")}>
                                    升中配套課程
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className={[classes.Col,"col-6 col-sm-6 col-md-3"].join(" ")}>
                        <Link to="/timetable">
                            <div className={[classes.Card,"card"].join(" ")}>
                                <h5 className="card-title"><FiIcon.FiClock className={classes.Icon}/></h5>
                                <div className={[classes.CardBody, classes.IconTitle,"card-body"].join(" ")}>
                                    課程時間表
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className={[classes.Col,"col-6 col-sm-6 col-md-3"].join(" ")}>
                        <Link to="/application">
                            <div className={[classes.Card,"card"].join(" ")}>
                                <h5 className="card-title"><AiIcon.AiOutlineForm className={classes.Icon}/></h5>
                                <div className={[classes.CardBody, classes.IconTitle,"card-body"].join(" ")}>
                                    報名
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                        <div className={[classes.Card4,"card"].join(" ")}>
                            <ImpDateTable/>
                        </div>
                    </div>
                </div>
            </div>
            </IconContext.Provider>
        </div>
        <ContactUs/>
        </React.Fragment>
    );
};

export default Home;