import React from 'react';
import {Link} from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import * as RiIcons from 'react-icons/ri';

import classes from './GroupDiscussion.module.css';

import Chart from '@assets/background/chart-support.jpg';

const GroupDiscussion = (props) => {
    return (
        <React.Fragment>
            <IconContext.Provider value={{size: "40px"}}>
            {/* <div className={classes.BackDrop}></div> */}
            <div className={classes.Photo}></div>
            {/* <img src={Backdrop} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/> */}
            <div className={classes.Body}>
                <div className={[classes.Container,"container"].join(" ")}>
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h1 className={classes.Title}>升中配套課程 / 服務 </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={Chart} alt="Banner" className={[classes.Image,"img-fluid"].join(" ")}/>
                    <Link
                        className={classes.Toggle}
                        data-bs-toggle="collapse" 
                        data-bs-target="#multiCollapseExample2" 
                        aria-expanded="false" 
                        aria-controls="multiCollapseExample2">
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <p className={classes.Title2}>小組討論技巧訓練班<RiIcons.RiArrowDownSLine className={classes.DropDown}/></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Link>
                    <div class="collapse  show" id="multiCollapseExample2">
                    <div className={[classes.Row, "row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-xl-6"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={["card-body"].join(" ")}>
                                    <p className={classes.TextTitle}>課程簡介︰</p>
                                    <ul className={[classes.List, classes.Text].join(" ")}>
                                        <li>根據多間中學的小組討論面試形式而設計</li>
                                        <li>筆記收錄不同中學的過往試題 (Past Questions)，內容更貼近實況</li>
                                        <li>練習不同的討論題型，訓練思考，提升臨場表現</li>
                                        <li>包括廣東話 & 英語練習，以便應付不同面試需要</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.Col,"col-12 col-xl-6"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={["card-body"].join(" ")}>
                                    <p className={classes.TextTitle}>其他資料：</p>
                                    <ul className={[classes.List2, classes.Text].join(" ")}>
                                        <li><span>對象 ︰</span> 小五、小六學生</li>
                                        <li><span>教學語言 ：</span> 英語</li>
                                        <li><span>師生比例 ：</span> 最多6人一班</li>
                                        <li><span>學費	 ：</span>	$1600</li>	
                                        <li><span style={{color: "var(--pink-bg-color)"}}>課程共8堂，每堂1小時30分鐘 (包每名學生一本指定教材)</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    {/* ----- */}
                    <Link
                        className={classes.Toggle}
                        data-bs-toggle="collapse" 
                        data-bs-target="#multiCollapseExample1"
                        aria-expanded="false" 
                        aria-controls="multiCollapseExample1">
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h1 className={classes.Title2}>面試英語會話班<RiIcons.RiArrowDownSLine className={classes.DropDown}/></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Link>
                    <div class="collapse" id="multiCollapseExample1">
                    <div className={[classes.Row, "row"].join(" ")}>
                        
                            <div className={[classes.Col,"col-12 col-xl-6"].join(" ")}>
                                <div className={[classes.Card,"card"].join(" ")}>
                                    <div className={["card-body"].join(" ")}>
                                        <p className={classes.TextTitle}>課程簡介︰</p>
                                        <ul className={[classes.List, classes.Text].join(" ")}>
                                            <li>以面試為本的英語會話培訓課程</li>
                                            <li>學習更多適用於面試的英語詞彙及句式，並糾正常犯錯誤</li>
                                            <li>全英語授課，大量練習英語會話的實戰機會</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={[classes.Col,"col-12 col-xl-6"].join(" ")}>
                                <div className={[classes.Card,"card"].join(" ")}>
                                    <div className={["card-body"].join(" ")}>
                                        <p className={classes.TextTitle}>其他資料：</p>
                                        <ul className={[classes.List2, classes.Text].join(" ")}>
                                            <li><span>對象 ︰</span> 小五、小六學生</li>
                                            <li><span>教學語言 ：</span> 英語</li>
                                            <li><span>師生比例 ：</span> 最多6人一班</li>
                                            <li><span>學費	 ：</span>	$1550</li>	
                                            <li><span style={{color: "var(--pink-bg-color)"}}>課程共8堂，每堂1小時15分鐘 (包每名學生一本指定教材)</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                       
                    </div>
                     </div>
                    {/* ----- */}
                    <Link
                        className={classes.Toggle}
                        data-bs-toggle="collapse" 
                        data-bs-target="#multiCollapseExample3" 
                        aria-expanded="false" 
                        aria-controls="multiCollapseExample3">
                    <div className={[classes.Row,"row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-lg-12"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={[classes.CardBody,"card-body"].join(" ")}>
                                    <h1 className={classes.Title2}>全方位升中資訊講座<RiIcons.RiArrowDownSLine className={classes.DropDown}/></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Link>
                    <div class="collapse" id="multiCollapseExample3">
                    <div className={[classes.Row, "row"].join(" ")}>
                        <div className={[classes.Col,"col-12 col-xl-12"].join(" ")}>
                            <div className={[classes.Card,"card"].join(" ")}>
                                <div className={["card-body"].join(" ")}>
                                    <p className={classes.TextTitle}>簡介︰</p>
                                    <ul className={[classes.List, classes.Text].join(" ")}>
                                        <li>翰林薈將不定期舉辦升中講座，向家長及學生講解升中機制、選校策略、面試技巧等實用資訊</li>
                                        <li>由資深升中顧問或前名校面試老師主持</li>
                                        <li>出席家長可獲「升中必備攻略」小冊子乙份</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    {/* ----- */}
                </div>
            </div>
            </IconContext.Provider>
        </React.Fragment>
    );
};

export default GroupDiscussion;